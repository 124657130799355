/* Navbar.css */
/* .om_logo {
  object-fit: cover;
  width: 100px;
  height: 100px;
  height: 3rem;
  width: 4rem;
} */

.navbar {
  height: 5rem;
  width: 100vw;
  max-width: 100rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 3;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(2, 48, 71, 0.8);
}

.desktopMenuListItem {
  margin: 1rem;
  cursor: pointer;
}

.desktopMenuListItem:hover {
  color: #b100e8;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #6a00f4;
}

/* .desktopMenuImg {
  object-fit: cover;
  height: 1rem;
  width: 1rem;
  margin: 1rem;
} */

.desktopMenuBtn {
  background: white;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;
  border-radius: 3rem;
  cursor: pointer;
}

.hireBtn {
  object-fit: cover;
  margin: 0 0.25rem;
  height: 0.5rem;
  cursor: pointer;
}

.active {
  color: #b100e8;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #6a00f4;
}

.navMenu {
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  /* align-items: center;  */
  padding: 0.5rem;
  /* max-width: 50%; */
  background: rgb(40, 40, 40);
  border-radius: 1rem;
}

.listItem {
  color: white;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
  cursor: pointer;
}

.mobmenu {
  display: none;
  object-fit: cover;
  height: 1.8rem;
}

@media screen and (max-width: 720px) {
  .mobmenu {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 1.5rem;
  }
  .active {
    color: #b100e8;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #6a00f4;
  }
  .desktopMenu {
    display: none;
  }
  .desktopMenuBtn {
    display: none;
  }
  .o_logo {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .mobmenu {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 3rem;
  }
}
