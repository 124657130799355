#contact {
  /* max-height: calc(120vw - 1rem); */
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}

.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}

.name,
.email,
.msg {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
}

.submitBtn {
  background: white;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.linkContainer {
  display: flex;
  flex-direction: row; /* Arrange items horizontally */
  align-items: center; /* Center vertically */
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
}

@media screen and (max-width: 791px) {
  .contactPageTitle {
    font-size: 6vw;
  }
  .contactDesc {
    font-size: 3vw;
  }
}
