/* #work {
  margin: 0 auto;
  max-height: calc(100vw - 4rem);
  width: 100vw;
  max-width: 80rem;
  text-align: center;
  padding: 0 1rem;
} */

#work {
  margin: 0 auto;
  max-width: 80rem;
  width: 90%;
  text-align: center;
  padding: 0 1rem;
}

/* work.css */
.workImgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.workItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.workImg {
  width: 20rem;
  height: auto;
  margin: 0;
  /* border-radius: 1rem; */
}

.workTitle {
  margin: 1rem 0;
  font-size: 2.5rem;
}
.workItem p:nth-child(1) {
  font-size: 0.7rem;
  color: white;
}

.workDesc {
  font-weight: 300;
  font-size: 10px;
  /* max-width: 45rem; */
}

.workBtn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 2rem;
  background: white;
  font-size: 1rem;
  cursor: pointer;
}

.descriptionBox {
  background-color: rgb(40, 40, 40);
  padding: 10px;
  text-align: center;
  margin: 0;
  color: white;
  width: 20rem; /* Set the width to match the image width */
}

/* Add this CSS to your work.css file */
.imageContainer {
  position: relative;
  overflow: hidden;
}

.hoverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageContainer:hover .hoverOverlay {
  opacity: 1;
}

.sourceCodeButton,
.liveSiteButton {
  margin: 20px;
  padding: 5px 12px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.sourceCodeButton:hover,
.liveSiteButton:hover {
  background-color: #333;
  color: white;
}

@media screen and (max-width: 791px) {
  .workImg {
    width: 100%; /* Adjust this width as needed for proper alignment */
    height: auto; /* Let the height adjust automatically */
    margin: 0; /* Add margin for spacing between images */
  }

  .workItem p:nth-child(1) {
    font-size: 1.5vw;
    color: white;
  }

  .descriptionBox {
    width: 100%;
  }

  .workImgs {
    flex-direction: row; /* Change flex-direction to row to display 2 by 2 grid */
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .workItem {
    width: calc(
      45% - 10px
    ); /* Adjust the width to fit 2 items in a row with spacing */
    align-items: center;
    text-align: center;
  }

  .workTitle {
    font-size: 6vw;
  }
}

@media screen and (max-width: 540px) {
  .sourceCodeButton,
  .liveSiteButton {
    font-size: 0.4rem;
    padding: 0.3rem 1rem;
  }
}
