#intro {
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  /* margin-bottom: 1rem; */
  display: flex;
  flex-direction: flex-end;
  margin-left: 5rem;
}

.introContent {
  height: 100vh;
  width: calc(100% - 30rem); /* Adjusted width */
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Changed to justify-content */
  margin-left: 5rem;
  margin-top: 6rem;
  margin-right: 5rem; /* Added margin-right */
}
.introText {
  font-size: 3rem;
  font-weight: 700;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
}

.introName {
  color: #b100e8;
  display: inline; /* Ensure that the name remains inline */
}

.introRole {
  font-weight: 700;
}

.developer,
.firstName,
.lastName {
  display: block;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

.introName {
  color: #b100e8;
}

.btn {
  background: white;
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
}

.bgImg {
  height: 100%; /* Set a fixed height */
  max-width: 25%; /* Limit the maximum width */
  object-fit: cover; /* Maintain aspect ratio and cover container */
  border-radius: 20%;
  overflow: hidden;
  margin-right: 13rem;
  margin-top: 10rem; /* Decrease the margin-top to adjust the image position */
}

/* .bg {
  border-radius: 20%;
  max-width: 25%;
  position: absolute;
  top: -4rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
} */

.introPara {
  white-space: normal;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.5rem 0;
  margin-bottom: -0.5rem;
}

@media screen and (max-width: 878px) {
  .bgImg {
    display: none;
  }
  .introContent {
    margin-left: -1rem;
  }
  .introText {
    font-size: 2rem;
  }
  .introPara {
    white-space: nowrap; /* Prevent excessive wrapping */
  }
  .btn {
    font-size: 0.5rem;
    white-space: nowrap;
  }
}
@media screen and (max-width: 500px) {
  .introText {
    font-size: 2.5rem;
    border-radius: 1rem;
  }

  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.5rem;
    white-space: nowrap;
  }
}
@media screen and (max-width: 409px) {
  .introText {
    font-size: 2.5rem;
  }
  .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.5rem;
    border-radius: 1rem;
  }
}
